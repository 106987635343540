/* global  */ // tUrl等のグローバル変数を宣言する

import 'core-js/features/number/is-nan' // SwiperのIE対応
import 'custom-event-polyfill' // SwiperのIE対応
import { Autoplay, Controller, EffectFade, Navigation, Pagination, Swiper } from 'swiper'
Swiper.use([Autoplay, Controller, EffectFade, Navigation, Pagination]) // Swiper宣言
// その他、Swiperで使う機能があったら上に追加で宣言する
// A11y, Autoplay, Controller, EffectCoverflow, EffectCube, EffectFade, EffectFlip, HashNavigation, History, Keyboard, Lazy, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom,

// import PerfectScrollbar from 'perfect-scrollbar' // 独自スクロールバー
// position:styckyのポリフィル
// Stickyfill.add(document.querySelectorAll('.sticky'))

jQuery(function ($) {
  $('.js-acc').on('click', function () {
    $(this).toggleClass('open')
    $(this).next().slideToggle()
  })

  /*
          ######  ##      ## #### ########  ######## ########
         ##    ## ##  ##  ##  ##  ##     ## ##       ##     ##
         ##       ##  ##  ##  ##  ##     ## ##       ##     ##
          ######  ##  ##  ##  ##  ########  ######   ########
               ## ##  ##  ##  ##  ##        ##       ##   ##
         ##    ## ##  ##  ##  ##  ##        ##       ##    ##
          ######   ###  ###  #### ##        ######## ##     ##
  */
  // MV
  if ($('#mvSlide')[0]) {
    // eslint-disable-next-line no-unused-vars
    const mvSlide = new Swiper('#mvSlide', {
      speed: 1000,
      loop: true,
      effect: 'fade',
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.mv__slide-parts__next',
        prevEl: '.mv__slide-parts__prev',
      },
      pagination: {
        el: '.mv__slide-parts__pagenation',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        769: {},
      },
    })
  }

  function trunk8() {
    $('.js-t8.line1').trunk8({
      // 指定要素に行数制限をかける
      lines: 1,
      fill: '...',
    })
    $('.js-t8.line2').trunk8({
      // 指定要素に行数制限をかける
      lines: 2,
      fill: '...',
    })
    $('.js-t8.line3').trunk8({
      // 指定要素に行数制限をかける
      lines: 3,
      fill: '...',
    })
  }
  if ($('.js-t8')[0]) {
    // 要素を検出したら実行
    trunk8()
    $('.js-t8').removeAttr('title')
    $(window).on('load resize ', function () {
      trunk8()
      $('.js-t8').removeAttr('title')
    })
  }

  /*
  ##       ######  #######  #####  ######
  ##      ##    ##    ###  ##   ## ##   ##
  ##      ##    ##   ###   ####### ##   ##
  ##      ##    ##  ###    ##   ## ##   ##
  #######  ######  ####### ##   ## ######
  */
  /* global lozad */
  lozad('.lozad', {
    rootMargin: '3%',
    loaded: function (el) {
      el.classList.add('active') // 画面に交差した要素をactive
    },
  }).observe()

  if (document.getElementById('mv') != null) {
    // ヘッダー位置監視
    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          $('#header').removeClass('onMV')
        } else {
          $('#header').addClass('onMV')
        }
      })
    }
    let options = {
      threshold: 0.2,
    }
    let observer = new IntersectionObserver(callback, options)
    const target = document.querySelector('.mv')
    observer.observe(target)
  }

  if ($('.side-cta')[0]) {
    $(window).on('scroll', function () {
      if ($(window).scrollTop() === 0) {
        $('.side-cta').removeClass('show')
      } else {
        $('.side-cta').addClass('show')
      }
    })
  }
})
